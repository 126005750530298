<template>
  <SkillBadgeGroupModal 
    :show.sync="modalShow" 
    :skillList="skillList"
    :edgeName="edgeName" 
    @ok="skillSelectorOk" 
    @cancel="skillSelectorCancel" 
    @skillDeleted="skillSelectorDeleted"/>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SkillsEditor',
  components: {
    SkillBadgeGroupModal: () => import('@/components/modal/SkillBadgeGroupModal')
  },
  data() {
    return {
      value: ''
      , modalShow: false
      , skillList: []
      , edgeName: null
    };
  },
  computed: {
  },
  methods: {
    getValue() {
      return this.value
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing()
      }, 100);
    },
    skillSelectorOk({ value, customFields }) {
      const modifiedSkillList = value;
      //Check array size
      if (!this.params.value ||
          modifiedSkillList.length != this.params.value.length) {
        this.value = modifiedSkillList
        this.closePicker()
        return;
      }

      //Check properties
      const originSkills = this.params.value;
      let hasChanged = false;
      for (let i = 0, len = modifiedSkillList.length; i < len; i++) {
        const modified = modifiedSkillList[i]
        const found = originSkills.find(i => i.uuId == modified.uuId)
        if (found == null) {
          hasChanged = true
          break
        }

        if (found.level != modified.level) {
          hasChanged = true
          break
        }

        for (const f of customFields) {
          if (Object.hasOwn(found, f.name) && Object.hasOwn(modified, f.name)) {
           
            if ((f.type == 'String' || f.type == 'Enum<String>') && found[f.name].localeCompare(modified[f.name], undefined, { sensitivity: 'base' }) != 0) {
              hasChanged = true
              break
            } else if ((f.type != 'String' && f.type != 'Enum<String>') && found[f.name] != modified[f.name]) {
              hasChanged = true
              break
            }
          } else if (Object.hasOwn(found, f.name) || Object.hasOwn(modified, f.name)) {
            hasChanged = true
            break
          }
        }
      }
      if (hasChanged) {
        this.value = modifiedSkillList
      }
      this.closePicker;
    },
    skillSelectorCancel({ modifiedList=[]}={}) {
      if (modifiedList.length > 0) {
        const deletedIds = this.modifiedList.filter(i => i.action =='DELETE').map(i => i.uuId)
        const updatedList = this.modifiedList.filter(i => i.action == 'UPDATE')
        const remaining = this.value.filter(i => !deletedIds.includes(i.uuId))
        for (const item of remaining) {
          const obj = updatedList.find(i => i.uuId == item.uuId)
          if (obj == null) {
            continue
          }
          item.name = obj.name
        }
        this.value = remaining
      }
      this.closePicker()
    },
    prepareSkillList() {
      if(this.value != null) {
        for (const skill of this.value) {
          this.skillList.push(JSON.parse(JSON.stringify(skill)));
        }
      }
    },
    skillSelectorDeleted(/** { removedList=[]}={} **/) {
      
    }
    
  },

  created() {
    if (this.params.edgeName != null) {
      this.edgeName = this.params.edgeName;
    }
    this.modalShow = true;
    this.value = this.params.value;
    this.prepareSkillList();
  }
})
</script>